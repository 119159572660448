import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import Titulo from "../../components/Titulo";
import styled from "styled-components";
import CampoDigitacao from "../../components/CampoDigitacao";
import Botao from "../../components/Botao";
import autenticaStore from "../../stores/autentica.store";
import http from "../../http";
import foto from './perfil.png';

const BoxCustomizado = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  max-height: 90vh;
  overflow-y: auto;
  background-color: var(--cinza);
  border: none;
  border-radius: 5px;
  padding: 1em 5em;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const BotaoCustomizado = styled(Botao)`
    width: 50%;
    display: block;
    margin: 0 auto;
`
const User = styled.div`
img{
    width: 120px;
    height: 120px;
    border-radius: 100%;
    object-fit: cover;
}    
`
const Campo = styled.input`
    color:rgb(255, 255, 255);
    font-size: 15px;
    background:rgba(240, 240, 240, 0.08);
    margin-top: 3px;
    margin-bottom: 0px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 10px;
`

const BotaoCustom = styled.button`
  font-size: 14px;
  font-weight: 500;
  background-color:rgba(0, 0, 210, 0);
  color: white;
  padding: 5px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

interface IUsuarioPut {
    id: string,
    login: string,
    email: string,
    password: string | null,
    nome: string
}

export default function MeuPerfil() {
    const [idUser, setIdUser] = useState("");
    const [login, setLogin] = useState("");
    const [email, setEmail] = useState("");
    const [nome, setNome] = useState("");
    const [password, setPassword] = useState("");
    const [senhaVerificada, setSenhaVerificada] = useState("");
    const {usuario} = autenticaStore;
    const [erro, setErro] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [imageKey, setImageKey] = useState(Date.now());
    const bucketName = "https://rhsc-bucket-public.s3.us-east-1.amazonaws.com/";
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        },
    };

    useEffect(() => {
        //console.log("useEffect!")
        if (localStorage.getItem('userID') != null ) {
            http.get(`usuarios/${localStorage.getItem('userID')}`, axiosConfig)
            .then(resposta => {          
                setIdUser(resposta.data.id)
                setLogin(resposta.data.login)
                setEmail(resposta.data.email)
                setNome(resposta.data.nome)  
                setImageUrl(resposta.data.foto)          
            })
            .catch(erro => {
            //console.log("erro" + erro)
                alert("ERRO: "+ erro )
            
            })
        }
    }, [erro, imageUrl, loading]);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if(file.size > 1048576){
                alert("O arquivo deve ter no máximo 1MB!")
                return
            }else if(file.type != "image/jpeg" && file.type != "image/png"){
                alert("O arquivo deve ser uma imagem!")
                return

            }else{
                // Alterar o nome do arquivo
                const newFileName = `foto_perfil.${file.type.split('/')[1]}`;
                const newFile = new File([file], newFileName, { type: file.type });
                const formData = new FormData();
                const userID = localStorage.getItem('userID');
                formData.append('file', newFile);
                formData.append('user', userID!);
                setLoading(true);
                try{
                    const response = await http.post(`/file/upload/perfil`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${usuario.token}`,
                        },
                    })
                    //console.log('resposta.data', response.data);
                    setImageUrl(response.data);
                    setImageKey(Date.now());
                }catch(error){
                    //console.log('error', error);
                    alert("Erro ao enviar a imagem!/n" + error)
                }finally {
                    setLoading(false); // Finaliza o carregamento
                  }
                
                

                
            }
            
            //console.log('FILE FILE FILE FILE')
            //console.log(file)
            

        }
    };

    const handleSubmit = () => {
        if(password != senhaVerificada){
            alert("As senhas não conferem!")
            return
        }
        if(password.length < 8){
            alert("A senha deve ter no mínimo 8 caracteres!")
            return
        }
        const user: IUsuarioPut = {
            id: idUser,
            login: login,
            email: email,
            password: password == "" ? null : password,
            nome: nome
        }
        //console.log("chamada")
        http.put("usuarios",user ,axiosConfig)
        .then(resposta => {       
            //console.log('agora vai')  
            alert("Alteração efetuada com sucesso!")
            //console.log('teste')
            //console.log(resposta.data)
            /* setLogin(resposta.data.login)
            setEmail(resposta.data.email)
            setNome(resposta.data.nome)     */        
            
        })
        .catch(erro => {            
            alert("ERRO:" + erro)
            setErro(!erro)
        })     
    }

    return (
      <Container>
        {loading && (
        <Overlay>
          <CircularProgress color="warning" size="5rem"/>
        </Overlay>
        )}
        <BoxCustomizado>               
            <Container>
                <Titulo>Meu Perfil</Titulo>            
                <User>
                {imageUrl == '' || imageUrl == null ? <img src={foto} alt="Avatar"/> : <img src={`${bucketName}${imageUrl.replaceAll(" ", "+")}?${imageKey}`} alt="Avatar"/>}                                               
                </User>
                <BotaoCustom as="label">
                    {`${imageUrl == '' ? "Adicionar" : "Alterar"} Foto`}
                    <Campo type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                </BotaoCustom>
            </Container>               
            <form onSubmit={handleSubmit}>
                <Container>
                    <CampoDigitacao required={true} tipo="text" label="Login" valor={login} placeholder="Digite seu login" onChange={setLogin} />
                    <CampoDigitacao required={true} tipo="text" label="Nome" valor={nome} placeholder="Digite seu nome" onChange={setNome} />
                    <CampoDigitacao required={true} tipo="email" label="Email" valor={email} placeholder="Digite seu email" onChange={setEmail} />
                    <CampoDigitacao required={false} tipo="password" label="Alterar senha" valor={password} placeholder="******" onChange={setPassword} />
                    <CampoDigitacao required={false} tipo="password" label="Repita a nova senha" valor={senhaVerificada} placeholder="******" onChange={setSenhaVerificada} />                                                  
                    <br></br>
                    <BotaoCustomizado>Atualizar</BotaoCustomizado>
                </Container>                
            </form>
        </BoxCustomizado>
      </Container>
    )
}
