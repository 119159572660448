import styled, { createGlobalStyle } from 'styled-components';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommentIcon from '@mui/icons-material/Comment';
import ModalCRM from '../../../pages/Crm/Modal';
import { useState } from 'react';
import ICard from '../../../types/ICard';
import foto from './perfil.png';
import IColumn from '../../../types/IColumn';


const GlobalStyle = createGlobalStyle`
    .dragging {
        opacity: 0.5;
    }

    .cards-hover {
    background-color: rgb(46, 46, 46);
    border-radius: 8px;
}
`;

const KanbanCardStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color:rgb(207, 207, 207);
    border-radius: 7px;
    padding: 8px;
    padding-bottom: 2px;
    padding-top: 2px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
`
const Badge = styled.div`
    color:rgb(255, 255, 255);
    background-color: #92a5fb;
    width: fit-content;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 7px;
    margin-top: 2px;
`

const CardTitle = styled.p`
    display: flex;
    justify-content: space-between;
    padding: 0 2px;
    margin: 2px;
`

const CardInfos = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const CardIcons = styled.div`
    display: flex;
    gap: 18px;
`

const User = styled.div`
img{
    width: 38px;
    height: 38px;
    border-radius: 100%;
    object-fit: cover;
}    
`

interface KanbanCardProps {
    nome: string;
    data: ICard;
    onCardUpdate: () => void;
    colunas: IColumn[];
}

export default function KanbanCard({nome, data, onCardUpdate, colunas}: KanbanCardProps) {
    const [open, setOpen] = useState(false);
    const bucketName = "https://rhsc-bucket-public.s3.us-east-1.amazonaws.com/";
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      onCardUpdate()
    };
    //console.log("ENTROU CARDS")
    //console.log(data)

    return (
        <>
         <ModalCRM open={open} handleClose={handleClose} dados={data} colunas={colunas} /* colaboradoresFormatadoscolaboradoresFormatados colunasFormatadascolunasFormatadas *//>
        <GlobalStyle />               
        <KanbanCardStyle className="kanban-card" draggable="true" onClick={() => handleOpen()} onDragStart={(e) => e.dataTransfer.setData('text', data.id)}>
            <Badge>
                <span>Novo</span>
            </Badge>    
            <CardTitle>{nome}</CardTitle>
            <CardInfos>
                <CardIcons>
                    <p><CommentIcon/>{data.taskStatus}</p>
                    <p><AttachFileIcon/>{data.arquivos.length}</p>
                </CardIcons>                 
                <User>
                    {data.fotoAtendente == '' || data.fotoAtendente == null ? <img src={foto} alt="Avatar"/> : <img src={`${bucketName}${data.fotoAtendente!.replaceAll(" ", "+")}`} alt="Avatar"/>}
                </User>
            </CardInfos>                
        </KanbanCardStyle>
        </> 
    )
}