import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  background-color: black;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const DropdownContent = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  background-color: black;
  border-radius: 8px;
  min-width: 180px;  
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 60px; /* Posiciona o menu dropdown 40px abaixo do botão */
  right: 0; /* Alinha a lateral direita do menu com a lateral direita do botão */
  animation: ${({ show }) => (show ? fadeIn : fadeOut)} 0.3s ease-in-out;
`;

const DropdownItem = styled.a`
  color: white;
  border-radius: 8px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: black;
  &:hover {
    background-color:rgb(17, 17, 17);
  }
`;

interface DropdownMenuProps {
    onSelect: (value: string) => void;
  }

  const DropdownMenu: React.FC<DropdownMenuProps> = ({ onSelect }) => {
  const [show, setShow] = useState(false);

  const toggleDropdown = () => setShow(!show);

  const handleSelect = (value: string) => {
    onSelect(value);
    setShow(false);
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        <MenuIcon />
      </DropdownButton>
      <DropdownContent show={show}>
        <DropdownItem href="#" onClick={() => handleSelect('leads')}>Gestão de Leads</DropdownItem>
        <DropdownItem href="#" onClick={() => handleSelect('clientes')}>Gestão de Clientes</DropdownItem>
        <DropdownItem href="#" onClick={() => handleSelect('parceiros')}>Gestão de Parceiros</DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default DropdownMenu;