import KanbanCard from '../kanbanCard'; // Adjust the import path as necessary
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components'
import ModalCRM from '../../../pages/Crm/Modal';
import autenticaStore from "../../../stores/autentica.store";
import ICard from '../../../types/ICard';
import http from '../../../http';
import KanbanNewCard from '../kanbanNewCard';
import IColumn from '../../../types/IColumn';

const GlobalStyle = createGlobalStyle`
    .dragging {
        opacity: 0.5;
    }

    .cards-hover {
    background-color: rgb(46, 46, 46);
    border-radius: 8px;
}
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 97%;
    overflow-y: auto;
    background-color:rgb(77, 77, 77);
    border-radius: 7px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    padding: 12px;
    overflow: hidden;
    position: relative;
    min-width: 300px;
    :after {
        position: absolute;
        content: '';
        height: 5px;
        width: 100%;
        top: 0;
        left: 0;
    }
`
const Title = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-botton: -14px;
    h2 {
        font-size: 22px;
        color:rgb(202, 202, 202);
        font-weight: 600;
    }
`

const AddCard = styled.button`
    background-color:rgb(34, 34, 34);
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
    i {
        font-size: 30px;
        color:rgb(202, 202, 202);
    }
    
`
const KanbanCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`
interface ICardStatus{
    id: string;
    statusId: number;
}

interface KanbanColumnProps {
    name: string;
    cards: ICard[];
    id: number;
    refreshCards: () => void;
    colunas: IColumn[];
}

const KanbanColumn: React.FC<KanbanColumnProps> = ({ name, cards, id, refreshCards, colunas }) => {
    const [newCard, setNewCard] = useState(false);
    const {usuario} = autenticaStore;

    //console.log("CARDS DENTRO DE KANBANCOLUMN")
    //console.log(cards)

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        },
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, columnId: number) => {
        e.preventDefault();
        const cardId = e.dataTransfer.getData('text');        
        const cardAtualizado: ICardStatus = {
            id: cardId,
            statusId: columnId
        }

        try {
            http.put(`card`,cardAtualizado ,axiosConfig)
            .then(resposta => {
                //console.log("sucesso ao movimentar o card")
            })
            .catch(erro => {            
                alert("ERRO:" + erro)
            })   
        } catch (erro) {
            erro && alert('Erro ao cadastrar os dados')
        } 
    };

    const handleCloseNewCard = () => {
    setNewCard(false);
    };

    const AdicionarCard = () => {        
    setNewCard(true);
    };

    const handleCardAdded = () => {
    setNewCard(false);
    refreshCards();
    }

    const handleCardUpdate = () => {
        refreshCards();
      };

    
    return (
        <>
            <GlobalStyle />
            <Column 
                key={id}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e, id)}
                >
                <Title>
                <h2>{name}</h2>
                    <AddCard onClick={() => AdicionarCard()}>
                        <i> + </i>
                    </AddCard>
                </Title>
                <KanbanCards className="kanban-cards" draggable="false">
                    {newCard && <KanbanNewCard onClose={handleCloseNewCard} onCardAdded={handleCardAdded} columnId={id}/>}
                    {cards.map((card, index) => {
                        return <KanbanCard key={index} nome={card.nome} data={card} colunas={colunas} onCardUpdate={handleCardUpdate}/>;
                    })}

                </KanbanCards>
            </Column>
        </>
        
    )
}

export default KanbanColumn;