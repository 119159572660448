import React, { useRef, useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Grid, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@mui/material';
import { Box } from "@mui/material";
import styled, { keyframes } from 'styled-components';
import autenticaStore from "../../../stores/autentica.store";
import http from "../../../http";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import Botao from "../../../components/Botao";
import Select from 'react-select';
import IUser from "../../../types/IUser";
import IBodyRequest from "../../../types/IBodyRequest";
import IColumn from "../../../types/IColumn";
import ICard from "../../../types/ICard";
import Alert from '@mui/material/Alert';
import IProduto from "../../../types/IProduto";
import IPrecificacao from "../../../types/IPrecificacao";
import ITask from "../../../types/ITask";
import IArquivo from "../../../types/IArquivo";
import IPagamentoNF from "../../../types/IPagamentoNF";
import { set } from "mobx";

const BoxCustomizado = styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw; 
    max-height: 90vh;
    overflow-y: auto;
    background-color: var(--cinza);
    border: none;
    border-radius: 5px;
    padding: 1em 1em;

    @media (max-width: 768px) {
        width: 95vw; 
    }

    @media (max-width: 60vw) {
        width: 90vw; 
    }

     @media (max-width: 1400px) {
        width: 90vw; 
    }
`;

const SpanErro = styled.span`
    float: left;
    color: #bdbdbd;
    font-size: 14px;
    margin-top: -25px;
`

const Campo = styled.input`
    color:rgb(255, 255, 255);
    font-size: 15px;
    background:rgba(240, 240, 240, 0.08);
    margin-top: 3px;
    margin-bottom: 0px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 10px;
    &::placeholder {
    color: #aaa; /* Cor do placeholder */
`

const Rotulo = styled.label`
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--cinza-claro)
`

const InputMaskCustomizado = styled(InputMask)`
    color:rgb(255, 255, 255);
    font-size: 15px;
    background:rgba(240, 240, 240, 0.08);
    margin-top: 3px;
    margin-bottom: 0px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 10px;
    &::placeholder {
    color: #aaa; /* Cor do placeholder */
  }
`

const CampoTextoLongo = styled.textarea`
    color:rgb(255, 255, 255);
    background:rgba(240, 240, 240, 0.08);
    width: 100%;
    padding: 8px;
    margin-top: 3px;
    border-radius: 4px;  
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    min-height: 100px; /* Ajuste a altura mínima conforme necessário */
`;

const BotaoCustomizado = styled(Botao)`
    width: 50%;
    display: block;
    margin: 0 auto;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    cursor: pointer;    
`
const BotaoDownload = styled.button`
    background-color: rgb(0, 135, 197);
    border-radius: 3px;
    padding: 5px 9px;
    color: var(--branco);
    border: solid rgb(0, 135, 197);
    margin-top: 5px;
    font-weight: 700;
    line-height: 19px;
    &:hover {
        background-color: rgb(0, 114, 167);
    }
    &:active{
        border: solid rgb(0, 89, 131);
    }
`

const BotaoCustom = styled.button`
  font-size: 14px;
  font-weight: 500;
  background-color:rgb(29, 27, 153);
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
`;

const SelectCustomizado = styled(Select)`
 margin-top: 3px;
`;

const customStyles = {
    control: (provided: any) => ({
      ...provided,
      background: 'rgba(240, 240, 240, 0.08)',
      color: 'white',
      border: 'none',
      boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
      borderRadius: '3px',
    }),
    menu: (provided: any) => ({
      ...provided,
      background: 'rgb(109, 109, 109)',
      color: 'white',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isSelected ? 'rgba(255, 255, 255, 0.1)' : 'rgba(240, 240, 240, 0.08)',
      color: 'white',
      '&:hover': {
        background: 'rgba(240, 240, 240, 0.08)',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
    input: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: 'rgb(202, 202, 202)', // Ajuste a cor do placeholder aqui
    }),
  };

  const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface FloatingAlertProps {
  show: boolean;
}

const Badge = styled.div`
    color:rgb(255, 255, 255);
    background-color: #92a5fb;
    width: fit-content;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 7px;
    margin-top: 2px;
`

const FloatingAlert = styled(Alert)<FloatingAlertProps>`
  position: absolute;
  top: 5;
  left: 87%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 100%;
  max-width: 230px;
  animation: ${({ show }) => (show ? fadeIn : fadeOut)} 0.5s forwards;
`;


export default function ModalCRM({ open, handleClose, dados, colunas}: { open: boolean, handleClose: () => void, dados: ICard, colunas: IColumn[] }) {
    const {register, handleSubmit, reset, formState:{errors}, setValue, control, getValues, trigger} = useForm({ mode: 'onSubmit'});
    const [index, setIndex] = useState(0);      
    const [tasks, setTasks] = useState<ITask[]>([])
    const [arquivos, setArquivos] = useState<IArquivo[]>([]);
    const {usuario} = autenticaStore;
    const [colaboradores, setColaboradores] = useState<IUser[]>([]); 
    const [produtos, setProdutos] = useState<IProduto[]>([]);
    const [precificacao, setPrecificacao] = useState<IPrecificacao[]>([]);
    const [linksPgto, setLinksPgto] = useState<IPrecificacao[]>([]);
    const [linkselected, setLinkSelected] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [taskToRemove, setTaskToRemove] = useState<number | null>(null);
    const [fileToRemove, setFileToRemove] = useState<number | null>(null);
    const [openFileConfirm, setOpenFileConfirm] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [vendas, setVendas] = useState<IPagamentoNF[]>([])
    const [statusSelecionado, setStatusSelecionado] = useState<{value: number; label: string}>({value: 0, label: ''});
    const [produtoSelecionado, setProdutoSelecionado] = useState<{value: any; label: string}>({value: 0, label: ''});
    const [precoSelecionado, setPrecoSelecionado] = useState<{value: any; label: string}>({value: 0, label: ''});
    const [vendaSelecionada, setVendaSelecionada] = useState<{value: any; label: string}>({value: 0, label: ''});

    function formataMoeda(valor: number): string {
        return valor.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      }

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        },
    };

    const getCurrentDate = (): string => {
        const currentDate = new Date();
        return `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
      };
      
    const getDate31DaysAgo = (): string => {
    const date31DaysAgo = new Date();
    date31DaysAgo.setDate(date31DaysAgo.getDate() - 31);
    return `${date31DaysAgo.getFullYear()}-${String(date31DaysAgo.getMonth() + 1).padStart(2, '0')}-${String(date31DaysAgo.getDate()).padStart(2, '0')}`;
    };
    
      const agruparPagamentos = (vendas: IPagamentoNF[]) => {
        //console.log("ENTROU AGRUPAR PAGAMENTOS")
        const agrupados: { [key: string]: IPagamentoNF } = {};
        let ttlComprador = 0;
        let ttlBase = 0;
        let ttlTaxas = 0;
        let ttlComissao = 0;
        vendas.forEach(pagamento => {
          ttlComprador += pagamento.valorcomprador;
          ttlBase += pagamento.valorbase;
          ttlTaxas += pagamento.taxas;
          ttlComissao += pagamento.comissao;
          const key = `${pagamento.vendaId}-${pagamento.meiopagamento}-${pagamento.tipopagamento}`;
          if (!agrupados[key]) {
            agrupados[key] = { ...pagamento };
          } else {
            if(agrupados[key].meiopagamento != "STONE"){
              agrupados[key].parcelas += pagamento.parcelas;
            }        
            agrupados[key].valorcomprador += pagamento.valorcomprador;
            agrupados[key].valorbase += pagamento.valorbase;
            agrupados[key].taxas += pagamento.taxas;
            agrupados[key].comissao += pagamento.comissao;
    
            
          }
        });   
    
        return Object.values(agrupados).map(dado => ({
          ...dado,
          value: dado.pagamentoId,
          label: `${dado.cpfcnpj} - ${dado.nomecomercial} ${dado.nomesocial} - ${dado.produto.split(" - ")[1]} - ${formataMoeda(dado.valorbase)}`, // Concatena nomecomercial e nomesocial
          valorcomprador: formataMoeda(dado.valorcomprador), // Formata valorcomprador
          valorbase: formataMoeda(dado.valorbase), // Formata valorbase
          taxas: formataMoeda(dado.taxas), // Formata taxas
          comissao: formataMoeda(dado.comissao), // Formata comissao
          datapagamento: new Date(dado.datapagamento).toLocaleDateString('pt-BR'),
          datavenda: new Date(dado.datavenda).toLocaleDateString('pt-BR')
        }));
      };

    useEffect(() => {
        if(open){
            if(vendas.length === 0){
                let startDate = getDate31DaysAgo();
                let endDate = getCurrentDate();
                //console.log("DATAS: " + startDate + " - " + endDate)
                
                http.get<IPagamentoNF[]>(`venda/filtrarPorData?startDate=${startDate}&endDate=${endDate}`, axiosConfig)
                .then(resposta => {
                    const dadosAgrupados = agruparPagamentos(resposta.data);            
                    setVendas(dadosAgrupados);
                    

                    let vendasFormatadas = dadosAgrupados.map(dado => ({
                        value: dado.pagamentoId,
                        label: dado.label
                    }));

                    //console.log("--------------VENDA SELECIONADA-----------")
                    //console.log(dados)
                    //console.log(vendasFormatadas)
                    setVendaSelecionada(vendasFormatadas.filter(p => p.value === dados.venda)[0])
                })
                .catch(erro => {
                    alert(erro);
                });  
            } 

            if(produtos.length === 0){
                http.get<IBodyRequest<IProduto>>('produtos?size=500', axiosConfig)
                .then(resposta => {
                    setProdutos(resposta.data.content.filter(produto => produto.active))   
                    //console.log("--------------PRODUTO SELECIONADO-----------")      
                    let prod = resposta.data.content
                    let formatados = prod.map(produto => ({
                        value: produto.id,
                        label: produto.nome
                    }))
                    setProdutoSelecionado(formatados.filter(p => p.value == dados.produtoId)[0])
                })
                .catch(erro => {
                    alert(erro)
                })
            }

            if(precificacao.length === 0){
                http.get<IBodyRequest<IPrecificacao>>(`precificacao?size=500`, axiosConfig)
                .then(resposta => {
                    setPrecificacao(resposta.data.content.filter(precifica => precifica.active))
                    let prec = resposta.data.content
                    setLinksPgto(prec)
                    //console.log("PRECIFICACAO")
                    //console.log(prec)
                    let formatados = prec.map(precifica => ({
                        value: precifica.id,
                        label: precifica.cupom + " - " + formataValor(precifica.moeda, precifica.valor)
                    }))
                    setPrecoSelecionado(formatados.filter(p => p.value == dados.precoId)[0])
                })
                .catch(erro => {
                    alert(erro)
                }) 
            }

            if(colaboradores.length === 0){
                http.get<IBodyRequest<IUser>>(`usuarios`, axiosConfig)
                .then(resposta => { 
                    setColaboradores(resposta.data.content)
                })
                .catch(erro => {
                    alert(erro)
                })  
            }
        
            http.get<ICard>(`card/${dados.id}`, axiosConfig)
            .then(resposta => {

                let colunasFormatadas = colunas.map(column => ({
                    value: column.id,
                    label: column.tipo + " - " + column.name
                }));                
                setStatusSelecionado(colunasFormatadas.filter(c => c.value == resposta.data.statusId)[0])
                setValue('status', colunasFormatadas.filter(c => c.value == resposta.data.statusId)[0])
                //console.log("RESPOSTA")
                //console.log(resposta)
                setValue('atendente', {value: resposta.data.atendenteId, label: resposta.data.atendente})
                setValue('nome', resposta.data.nome)    
                setValue('lastUpdate', resposta.data.lastStatusUpdate)
                setValue('cardID', resposta.data.id)
                setValue('status', resposta.data.statusId)
                setValue('email', resposta.data.email)
                setValue('linkedin', resposta.data.linkedin)
                setValue('telefone', resposta.data.telefone != null ? resposta.data.telefone : "") 
                setValue('produto', resposta.data.produto)
                setValue('preco', resposta.data.preco)
                setValue('objetivo', resposta.data.objetivo) 
                setValue('descricao', resposta.data.descricao)
                setValue('tags', resposta.data.tags)
                setValue('cliente', resposta.data.cliente)
                setValue('venda', resposta.data.venda)
                setValue('linkPgto', resposta.data.linkPgto)
                setTasks(resposta.data.tasks)
                setArquivos(resposta.data.arquivos)
                //console.log("ARQUIVOS")
                //console.log(resposta.data.arquivos)
            

            })
            .catch(erro => {
                alert(erro);
            });
            
        }        
    
      }, [open, showAlert]);

      

      

      const vendasFormatadas = vendas.map(dado => ({
        value: dado.pagamentoId,
        label: `${dado.cpfcnpj} - ${dado.nomecomercial} ${dado.nomesocial} - ${dado.produto.includes(" - ") ? dado.produto.split(" - ")[1] : dado.produto} - ${formataMoeda(dado.valorbase)}`
      }));

    const colaboradoresFormatados = colaboradores.map(colaborador => ({
        value: colaborador.id,
        label: colaborador.nome
    }));

    const colaboradoresTask = [
        { value: 0, label: 'Cliente' },
        ...colaboradores.map(colaborador => ({
          value: colaborador.id,
          label: colaborador.nome
        }))
      ];

    const addTarefa = () => {
        setTasks([...tasks, { id: '', cardId: '', description: '', createdAt: '' , estimatedDate: '' , conclusionDate: '', assignedTo: '', assignedToId: '' , done: false }]);
        setIndex(index + 1)
        reset({ id: '', cardId: '', description: '', createdAt: '' , estimatedDate: '' , conclusionDate: '', assignedTo: '', assignedToId: '' , done: false });      
    }

    const confirmRemoveTask = async () => {
        if (taskToRemove !== null) {
          let idTask = getValues(`id[${taskToRemove}]`);
          //console.log("INDEX REMOVE: " + taskToRemove);
          //console.log("ID TASK: " + idTask);
          if (idTask != ""){
            try {
                const response = await http.delete(`task/${idTask}`, axiosConfig);
                //console.log('Tarefa deletada com sucesso:', response.data);
                setValue(`id[${taskToRemove}]`, "");
                setValue(`cardId[${taskToRemove}]`, "");
                setValue(`description[${taskToRemove}]`, "");
                setValue(`createdAt[${taskToRemove}]`, "");
                setValue(`estimatedDate[${taskToRemove}]`, "");
                setValue(`conclusionDate[${taskToRemove}]`, "");
                setValue(`assignedTo[${taskToRemove}]`, "");
                setValue(`assignedToId[${taskToRemove}]`, "");
                setValue(`done[${taskToRemove}]`, false);
        
                setTasks(tasks.filter((_, index) => index !== taskToRemove));
                setIndex(index - 1);
                //console.log("ENTROU NO REMOVE TASK")
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
              } catch (erro) {
                alert(erro);
              }
          }else{
            setTasks(tasks.filter((_, index) => index !== taskToRemove));
            setIndex(index - 1);
            //console.log("ENTROU NO REMOVE TASK ELSE")
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        }
        }
        handleCloseConfirm();
      };

      const confirmRemoveFile = async () => {
        if (fileToRemove !== null) {
          let i = fileToRemove;
          let fileId = getValues(`id[${i}]`);
          const arquivoId = getValues(`fileId[${i}]`);
          const fileName = getValues(`fileName[${i}]`);

          if (fileId != ""){
            try {
                const response = await http.delete(`file/delete/${dados.id}/${arquivoId}/${fileName}`, axiosConfig);
                //console.log('Tarefa deletada com sucesso:', response.data);
                setValue(`fileId[${i}]`, "");
                setValue(`fileName[${i}]`, "");
                setValue(`fileData[${i}]`, "");
        
                setArquivos(arquivos.filter((_, index) => index !== i));
                //console.log("ENTROU NO REMOVE FILE")
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
              } catch (erro) {
                alert(erro);
              }
          }else{
            setArquivos(arquivos.filter((_, index) => index !== i));
            //console.log("ENTROU NO REMOVE FILE ELSE")
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        }
        }
        handleCloseFileConfirm();
      };

      const onSubmit = (data: any) => {
        //console.log(data);
      };

      const onInputblur = (inputData: object) => {
        //console.log("O QUE FOI?")
        //console.log(inputData)
        http.put(`card`,inputData ,axiosConfig)
        .then(resposta => {       
            //console.log('agora vai')    
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        })
        .catch(erro => {            
            alert("ERRO:" + erro)

        })     
      };

      const onTaskblur = (inputData: object, i: number) => {
        //console.log("ENVIO TASK")
        //console.log(inputData)

        if((inputData as any).id === ""){
            if ('id' in inputData) {
                inputData.id = null;
              }
            http.post(`task`,inputData ,axiosConfig)
            .then(resposta => {     
                //console.log('agora vai task post') 
                //console.log(resposta)  
                let id = resposta.data.id
                setValue(`id[${i}]`, id)
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
            })
            .catch(erro => {            
                alert("ERRO:" + erro)
    
            })
        }else{

            http.put(`task`,inputData ,axiosConfig)
            .then(resposta => {       
                //console.log('agora vai task')    
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
            })
            .catch(erro => {            
                alert("ERRO:" + erro)

            })     
        }
      };

      const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //console.log("---------- OBJETO ARQUIVO -----------------");
        //console.log(e.target.files);
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0];
          const formData = new FormData();
          formData.append('file', file);
          formData.append('card', dados.id);
      
          const axiosConfig = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${usuario.token}`,
            },
          };
      
          http.post(`file/upload`, formData, axiosConfig)
            .then(resposta => {       
              //console.log('--------------------upload concluido-----------------');   
              //console.log(resposta);
              alert("Arquivo enviado com sucesso"); 
              setShowAlert(true);
              setTimeout(() => setShowAlert(false), 3000);
            })
            .catch(erro => {            
              alert("ERRO:" + erro);
            });
        }
      };

      const precificacaoFormatados = (filtro: any) =>{
        
        if(filtro === "" || filtro == undefined) return []
        
        let filtrado = precificacao.filter(precifica => precifica.idproduto === filtro.value)
 
        
        let precifica = filtrado.map(precifica => ({
            value: precifica.id,
            label: precifica.cupom + " - " + formataValor(precifica.moeda, precifica.valor)
        }));
        
        return precifica        
    }

    const formataValor = (moeda: string, valor: string | number) => {
        if(moeda === "REAL") return `R$ ${valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        if(moeda === "DOLAR") return `U$ ${valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        if(moeda === "EURO") return `€ ${valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`        
    }

    const handleOpenConfirm = (index: number) => {
        setTaskToRemove(index);
        setOpenConfirm(true);
    };    
    const handleCloseConfirm = () => {
        setTaskToRemove(null);
        setOpenConfirm(false);
    };
    const handleOpenFileConfirm = (index: number) => {
        setFileToRemove(index);
        setOpenFileConfirm(true);
    };
    const handleCloseFileConfirm = () => {
        setFileToRemove(null);
        setOpenFileConfirm(false);
    };

    const handleDownloadFile = (index: number) => {
        const arquivoId = getValues(`fileId[${index}]`);
        const fileName = getValues(`fileName[${index}]`);
      
        http.get(`file/download/${dados.id}/${arquivoId}/${fileName}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
          },
          responseType: 'blob', // Importante para obter o arquivo como um blob
        })
        .then(resposta => {
          // Cria um link temporário para o blob do arquivo
          const url = window.URL.createObjectURL(new Blob([resposta.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); // Define o nome do arquivo
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url); // Libera o objeto URL
      
          //console.log("Download efetuado com sucesso!");
        })
        .catch(erro => {
          alert(erro);
        });
      };
      
      const isValidDate = (dateString: string): boolean => {
        const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        const match = dateString.match(regex);      
        if (!match) {
          return false;
        }      
        const day = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1;
        const year = parseInt(match[3], 10);
        if (day < 1 || day > 31 || month < 0 || month > 11 || year < 1000 || year > 9999) {
          alert("Data inválida")
          return false;
        }
        const date = new Date(year, month, day);
        if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
          alert("Data inválida")
          return false;
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (date < today) {
            alert("Data não pode ser inferior a data atual")
          return false;
        }      
        return true;
      };

      const formatDate = (dateString: string | null): string => {
        if (!dateString) {
          return "";
        }
        // Verifica se a data está no formato YYYY-MM-DDTHH:mm:ss
        const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
        const match = dateString.match(regex);
      
        if (!match) {
          throw new Error("Formato de data inválido");
        }
      
        const year = match[1];
        const month = match[2];
        const day = match[3];
      
        // Retorna a data no formato DD/MM/YYYY
        return `${day}/${month}/${year}`;
      };

   
    return (
        <>            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                
                <BoxCustomizado>
                {showAlert && (
                <FloatingAlert show={showAlert} variant="filled" severity="success">
                    Card atualizado com Sucesso!
                </FloatingAlert>
                )}                 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12}>
                            <h2 style={{textAlign: "center", color: 'white'}}>Card</h2>
                        </Grid>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Rotulo>Tags: </Rotulo>  
                            <Badge>
                                <span>Novo</span>
                            </Badge>  
                            
                        </Grid>
                            <Grid item xs={6} md={3}>
                                <Rotulo>Atendente</Rotulo>                                
                                <Controller
                                    name="atendente"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectCustomizado
                                        {...field}
                                        value={field.value}
                                        options={colaboradoresFormatados}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            let card = {
                                                id: dados.id,
                                                atendenteId: e.value,
                                                atendente: e.label,
                                                fotoAtendente: e.value + "/foto_perfil.png"
                                            }
                                            onInputblur(card)
                                        }}
                                        isSearchable
                                        styles={customStyles}
                                        />
                                    )}
                                    />                                                             
                            </Grid> 
                            <Grid item xs={6} md={3}>
                                <Rotulo>Etapa</Rotulo>                                
                                <Controller
                                    name="status"
                                    control={control}                                    
                                    render={({ field }) => (
                                        <SelectCustomizado
                                        {...field}                                        
                                        value={statusSelecionado}
                                        options={colunas
                                            .filter(column => column.tipo === 'leads' || column.tipo === 'clientes')
                                            .map(column => ({
                                              value: column.id,
                                              label: column.tipo + " - " + column.name
                                            }))}
                                        onChange={(newValue: any) => {
                                            field.onChange(newValue);
                                            let card = {
                                                id: dados.id,                                                
                                                statusId: newValue.value,
                                            }
                                            onInputblur(card)
                                            setStatusSelecionado(newValue)
                                        }}
                                        isSearchable
                                        styles={customStyles}
                                        />
                                    )}
                                />                                                                
                            </Grid> 
                                                        
                            <Grid item xs={6} md={3}>
                                <Rotulo>Ultimo Update</Rotulo>
                                <Campo type="text" readOnly {...register('lastUpdate')} />
                            </Grid> 
                            <Grid item xs={6} md={3}>
                                <Rotulo>ID</Rotulo>
                                <Campo type="text" readOnly {...register('cardID')} />
                            </Grid>

                            <Grid item xs={12} md={6}>                                                                                     
                                <Rotulo>Nome *</Rotulo>                                
                                <Campo type="text" placeholder="" maxLength={100} {...register('nome',{
                                onBlur: (e) => {
                                    let card = {
                                        id: dados.id,
                                        nome: e.target.value                                        
                                    }
                                    onInputblur(card)
                                },
                                required: 'Campo obrigatório',
                                minLength: {
                                    value: 3,
                                    message: 'Deve ter no mínimo 3 caracteres'
                                },
                                maxLength: {
                                    value: 100,
                                    message: 'Deve ter no máximo 100 caracteres'
                                }
                                })} />
                                 {errors.nome && typeof errors.nome.message === 'string' && <SpanErro>{errors.nome.message}</SpanErro>}                         
                            </Grid>   

                            <Grid item xs={6} md={3}>
                                <Rotulo>E-mail</Rotulo>                                
                                <Campo type="text" placeholder="" maxLength={100} {...register('email', {
                                onBlur: (e) => {
                                    let card = {
                                        id: dados.id,
                                        email: e.target.value                                        
                                    }
                                    onInputblur(card)
                                },

                                }
                                )} />
                            </Grid>    

                            <Grid item xs={6} md={3}>
                                <Rotulo>Telefone</Rotulo>                                
                                <InputMaskCustomizado mask="(99) 99999-9999" maskChar={null} {...register('telefone', {
                                        onBlur: (e) => {
                                            let card = {
                                                id: dados.id,
                                                telefone: e.target.value                                        
                                            }
                                            onInputblur(card)
                                        },
                                        minLength: {
                                        value: 14,
                                        message: `Favor digitar um telefone válido`
                                        }})} />
                            </Grid>    

                            <Grid item xs={12} md={6}>
                                <Rotulo>LinkedIn</Rotulo>
                                <Campo type="text" placeholder="" maxLength={200} {...register('linkedin',{
                                    onBlur: (e) => {
                                        let card = {
                                            id: dados.id,
                                            linkedin: e.target.value                                        
                                        }
                                        onInputblur(card)
                                    },
                                })} />
                            </Grid>                        

                            <Grid item xs={12} md={3}>
                                <Rotulo>Produto</Rotulo>
                                <Controller name="produto" control={control}
                                    render={({ field }) => (<SelectCustomizado 
                                        {...field} 
                                        options={produtos.map(produto => ({
                                            value: produto.id,
                                            label: produto.nome
                                        }))} 
                                        value={produtoSelecionado}
                                        isSearchable
                                        styles={customStyles}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            setProdutoSelecionado(e)
                                            setValue('preco', '')
                                            trigger(`preco`)                                           
                                                                               
                                        }}/>
                                    )}                                                                                   
                                />
                            </Grid> 
                            
                            <Grid item xs={12} md={3}>
                                <Rotulo>Preço</Rotulo>
                                <Controller 
                                name="preco" 
                                control={control} 
                                rules={{ required: "Campo obrigatório" }}
                                render={({ field }) => {
                                    return (
                                        <SelectCustomizado 
                                        {...field} 
                                        options={precificacaoFormatados(getValues("produto"))} 
                                        isSearchable
                                        value={precoSelecionado}
                                        styles={customStyles}
                                        onChange={(e: any) => {
                                            field.onChange(e);   
                                            setPrecoSelecionado(e)   
                                            let linkPgto = linksPgto.filter(link => link.id === e.value)[0].linkpgto
                                            setValue('linkPgto',linkPgto)
                                            //console.log('PRECO SELECIONADO')
                                            //console.log(e)                                                 
                                            let card = {
                                                id: dados.id,
                                                produtoId: getValues("produto").value,
                                                produto: getValues("produto").label,
                                                precoId: e.value,
                                                preco: e.label,
                                                linkPgto: linkPgto                       
                                            }
                                            onInputblur(card)     
                                            }}
                                        />
                                        )
                                    }}
                                />
                            </Grid> 

                            <Grid item xs={9} md={6}>
                                <Rotulo>Venda</Rotulo>                                
                                <Controller
                                    name="venda"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectCustomizado
                                        {...field}
                                        value={vendaSelecionada}
                                        options={vendasFormatadas}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            setVendaSelecionada(e)
                                            //console.log("VENDA SELECIONADA: "+ e.value)
                                            //console.log(vendas)
                                            let cliente = vendas.filter(pagamento => pagamento.pagamentoId === e.value)[0].clienteId
                                            setValue('clienteID', cliente)

                                            let card = {
                                                id: dados.id,
                                                venda: e.value,
                                                cliente: cliente                                        
                                            }
                                            onInputblur(card)
                                        }}
                                        isSearchable
                                        styles={customStyles}
                                        />
                                    )}
                                    />                                                             
                            </Grid> 
                            <Grid item xs={3} md={1}>
                            <Rotulo>Cliente</Rotulo>
                            <Campo type="text" placeholder="" readOnly {...register('cliente')}  />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Rotulo>Link de Pagamento</Rotulo>
                                <Campo type="text" placeholder="" readOnly {...register('linkPgto')} />
                            </Grid>  

                            <Grid item xs={12} md={12}>
                                <Rotulo>Objetivo</Rotulo>
                                <Campo type="text" placeholder="" maxLength={200} {...register('objetivo',{
                                    onBlur: (e) => {
                                        let card = {
                                            id: dados.id,
                                            objetivo: e.target.value                                        
                                        }
                                        onInputblur(card)
                                    },
                                })} />
                            </Grid>  

                            <Grid item xs={12} md={12}>
                                <Rotulo>Descrição</Rotulo>
                                <CampoTextoLongo {...register('descricao', { 
                                    onBlur: (e) => {
                                        let card = {
                                            id: dados.id,
                                            descricao: e.target.value                                        
                                        }
                                        onInputblur(card)
                                    },
                                    maxLength: 2000 })} />
                                {errors.descricao && typeof errors.descricao.message === 'string' && <SpanErro>{errors.descricao.message}</SpanErro>}
                            </Grid> 
                            <Grid item xs={12} md={12} style={{paddingBottom: "10px"}}>
                                <BotaoCustomizado onClick={() => addTarefa()}>Adicionar Tarefa</BotaoCustomizado>  
                            </Grid>  
                            <Grid container spacing={1} style={{ marginLeft: '10px', marginBottom: "1px"}} >
                            
                            {tasks.map((tasks, index) => (
                                <React.Fragment key={index}>    
                                    <Grid container spacing={1} style={{ marginBottom: '10px', marginTop: "10px"}} >                              
                                        <input type="hidden" {...register(`id[${index}]`)} value={tasks.id} />
                                        
                                            <FormControlLabel
                                                control={<Checkbox color="warning" style={{color: "var(--cinza-claro)", marginLeft: "10px", marginTop: "10px", paddingRight: "0px"} }
                                                {...register(`done[${index}]`)}
                                                defaultChecked={tasks.done}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    if (isChecked) {
                                                      const currentDate = new Date();
                                                      const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}T${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;  
                                                      let task = {
                                                        id: getValues(`id[${index}]`),
                                                        done: isChecked,
                                                        conclusionDate: formattedDate,
                                                      };
                                                      setValue(`conclusionDate[${index}]`, formatDate(formattedDate));
                                                      tasks.conclusionDate = formattedDate;
                                                      onTaskblur(task, index);
                                                    } else {
                                                      let task = {
                                                        id: getValues(`id[${index}]`),
                                                        done: isChecked,
                                                        conclusionDate: ""
                                                      };
                                                      setValue(`conclusionDate[${index}]`, "");
                                                      tasks.conclusionDate = "";
                                                      onTaskblur(task, index);
                                                    }
                                            
                                                    
                                                  }}
                                                />}
                                                label=""
                                                labelPlacement="end"
                                                style={{marginTop: "-2px"}}
                                            />
                                        
                                        <Grid item xs={10} md={6} >
                                            <Controller
                                            name={`tasks[${index}].description`}
                                            control={control}
                                            defaultValue={tasks.description}
                                            render={({ field }) => (
                                                <Campo
                                                {...field}
                                                type="text"
                                                style={{ marginTop: '4px', marginBottom: '0px' }}
                                                maxLength={255}
                                                onBlur={(e) => {
                                                    if(e.target.value != tasks.description && e.target.value != "" && e.target.value != null){
                                                        //console.log('ENTROU TASK BLUR DE TESTE')
                                                    let task = {
                                                        id: getValues(`id[${index}]`),
                                                        cardId: dados.id,
                                                        description: e.target.value,
                                                    };
                                                    onTaskblur(task, index);
                                                }}}
                                                />
                                            )}
                                            />                                        
                                        </Grid>

                                        <Grid item xs={12} md={2} >                                                                           
                                            <Controller 
                                                name={`assignedTo[${index}]`}
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectCustomizado
                                                    {...field}
                                                    value={field.value}     
                                                    defaultValue={{value: tasks.assignedToId, label: tasks.assignedTo}}                                               
                                                    options={colaboradoresTask}
                                                    onChange={(e: any) => {
                                                        field.onChange(e);
                                                        let task = {
                                                            id: getValues(`id[${index}]`),
                                                            cardId: dados.id,
                                                            assignedToId: e.value,
                                                            assignedTo: e.label
                                                        }
                                                        onTaskblur(task, index)
                                                    }}
                                                    isSearchable
                                                    styles={customStyles}
                                                    />
                                                )}
                                                />                                                             
                                        </Grid> 

                                        <Grid container spacing={1} xs={11} md={3} style={{ marginLeft: '0px', marginTop: "0px" }}>  
                                            <Grid item xs={6} md={6}>  
                                                <Controller
                                                    name={`estimatedDate[${index}]`}
                                                    control={control}
                                                    defaultValue={formatDate(tasks.estimatedDate)}
                                                    render={({ field }) => (
                                                    <InputMaskCustomizado
                                                        mask="99/99/9999"
                                                        placeholder="Data prevista..."                                                                                                           
                                                        {...field}
                                                        onBlur={(e) => {
                                                            if(isValidDate(e.target.value)){
                                                                var format = e.target.value.split('/').reverse().join('-');
                                                                let task = {
                                                                id: getValues(`id[${index}]`),
                                                                cardId: dados.id,
                                                                estimatedDate: format + "T23:59:59",
                                                                };
                                                                onTaskblur(task, index);
                                                            }

                                                          }}
                                                    />
                                                    )}
                                                />    
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                            <Controller
                                                name={`conclusionDate[${index}]`}
                                                control={control}
                                                defaultValue={formatDate(tasks.conclusionDate)}
                                                render={({ field }) => (
                                                <Campo style={{ pointerEvents: 'none' }}
                                                    readOnly={true}                                                    
                                                    placeholder="Data realizada"
                                                    {...field}
                                                />
                                                )}
                                            />  
                                            </Grid>                                                                  
                                        </Grid>
      
                                        <BotaoCustomizado style={{backgroundColor: "rgb(134, 0, 0)", marginTop: "17px", padding: "0px", width: "23px", height: "23px"}} onClick={() => handleOpenConfirm(index)}>X</BotaoCustomizado>    
                                    </Grid>
                                </React.Fragment>   
                            ))}  
                            </Grid> 
                            <Grid container spacing={1} style={{marginTop: "23px", marginLeft: '10px'}}>
                                <Grid item xs={12} md={12} style={{paddingBottom: "10px", marginBottom: "3px"}}>
                                <BotaoCustom as="label">
                                    Anexar Arquivo
                                    <Campo type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                                </BotaoCustom>
                                </Grid> 
                                <Grid container spacing={1} style={{marginBottom: "1px"}} >
                                {arquivos.map((arquivo, indexArquivo) => (
                                    setValue(`fileId[${indexArquivo}]`, arquivo.id),
                                    <React.Fragment key={indexArquivo}>
                                        <input type="hidden" {...register(`fileId[${index}]`)} value={arquivo.id} />
                                        <Grid item xs={7} md={5} >
                                                    <Controller
                                                    name={`fileName[${indexArquivo}]`}
                                                    control={control}
                                                    defaultValue={arquivo.fileName}
                                                    render={({ field }) => (
                                                        <Campo
                                                        {...field}
                                                        type="text"
                                                        style={{ marginTop: '4px', marginBottom: '0px' }}
                                                        maxLength={255}
                                                        readOnly                                            
                                                        />
                                                    )}
                                                    />                                        
                                                </Grid>
                                                <Grid item xs={5} md={1}>                                                    
                                                    <Campo type="text"  readOnly value={formatDate(arquivo.createdAt)} {...register(`fileData[${indexArquivo}]`)} />
                                                </Grid>
                                                <Grid item xs={5} md={1}> 
                                                <BotaoDownload onClick={() => handleDownloadFile(indexArquivo)}>Download</BotaoDownload>
                                                </Grid>                                                
                                                <Grid item xs={1} md={1}> 
                                                <BotaoCustomizado style={{backgroundColor: "rgb(134, 0, 0)", marginTop: "12px", padding: "0px", width: "23px", height: "23px"}} onClick={() => handleOpenFileConfirm(indexArquivo)}>X</BotaoCustomizado>
                                                </Grid>
                                    </React.Fragment>   
                                ))}  
                                </Grid>
                            </Grid>                      
                        </Grid>    
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                            </Grid>
                              
                        </Grid>                    
                    </form>
                    <Dialog
                    open={openConfirm}
                    onClose={handleCloseConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                >
                    <DialogTitle style={{backgroundColor: 'rgba(26, 26, 26)' , color: 'white'}} id="alert-dialog-title">{"Confirmar Remoção"}</DialogTitle>
                    <DialogContent style={{backgroundColor: 'rgba(26, 26, 26)' , color: 'white'}}>
                    <DialogContentText style={{backgroundColor: 'rgba(26, 26, 26)' , color: 'white'}} id="alert-dialog-description">
                        Tem certeza que deseja remover esta tarefa?
                    </DialogContentText >
                    </DialogContent>
                    <DialogActions style={{backgroundColor: 'rgba(26, 26, 26'}}>
                    <Button style={{backgroundColor: 'rgba(70, 70, 70'}} onClick={handleCloseConfirm} color="success">
                        Cancelar
                    </Button>
                    <Button style={{backgroundColor: 'rgba(70, 70, 70'}} onClick={confirmRemoveTask} color="warning" autoFocus>
                        Confirmar
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openFileConfirm}
                    onClose={handleCloseFileConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                >
                    <DialogTitle style={{backgroundColor: 'rgba(26, 26, 26)' , color: 'white'}} id="alert-dialog-title">{"Confirmar Remoção"}</DialogTitle>
                    <DialogContent style={{backgroundColor: 'rgba(26, 26, 26)' , color: 'white'}}>
                    <DialogContentText style={{backgroundColor: 'rgba(26, 26, 26)' , color: 'white'}} id="alert-dialog-description">
                        Tem certeza que deseja remover este arquivo?
                    </DialogContentText >
                    </DialogContent>
                    <DialogActions style={{backgroundColor: 'rgba(26, 26, 26'}}>
                    <Button style={{backgroundColor: 'rgba(70, 70, 70'}} onClick={handleCloseFileConfirm} color="success">
                        Cancelar
                    </Button>
                    <Button style={{backgroundColor: 'rgba(70, 70, 70'}} onClick={confirmRemoveFile} color="warning" autoFocus>
                        Confirmar
                    </Button>
                    </DialogActions>
                </Dialog>
                </BoxCustomizado>
            </Modal >
        </>
    );
}