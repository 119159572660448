import React, { useState, useEffect } from "react";
import { Grid, Modal } from '@mui/material';
import { Box } from "@mui/material";
import styled from "styled-components";
import autenticaStore from "../../../stores/autentica.store";
import { useForm} from "react-hook-form";
import ICard from "../../../types/ICard";

const BoxCustomizado = styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw; 
    max-height: 90vh;
    overflow-y: auto;
    background-color: var(--cinza);
    border: none;
    border-radius: 5px;
    padding: 1em 1em;

    @media (max-width: 768px) {
        width: 95vw; 
    }
`
export default function ModalArquivados({ open, handleClose}: { open: boolean, handleClose: () => void}) {
    const {handleSubmit} = useForm({ mode: 'onSubmit' });
    const {usuario} = autenticaStore;
    const [cards, setCards] = useState<ICard[]>([]); 
    
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        },
    };

    useEffect(() => {
        if(!open){
          if(cards.length === 0){
            /* http.get<IBodyRequest<IUser>>(`usuarios`, axiosConfig)
            .then(resposta => {
                let atend = colaboradores.filter(c => c.login == usuario.login);
                if(atend.length > 0){   
                    //console.log("atendente: " + atend[0].nome)
                    setAtendente(atend[0].nome)
                }else{
                    setAtendente("")
                }    
                setColaboradores(resposta.data.content)
            })
            .catch(erro => {
                alert(erro)
            })   */
          }          
        }
        
    
    }, [open]);

    const onSubmit = (data: any) => {
    //console.log(data);
    };   
    return (
        <>
            {<Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <BoxCustomizado>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12}>
                            <h2 style={{textAlign: "center", color: 'white'}}>Arquivados</h2>
                        </Grid>
                        <Grid container spacing={2}>
                          
                        </Grid>    
              
                    </form>
                </BoxCustomizado>
            </Modal >}
        </>
    );
}