import React, { useEffect, useState } from 'react';
import autenticaStore from '../../stores/autentica.store';
import styled from "styled-components";
import IColumn from '../../types/IColumn';
import http from '../../http';
import KanbanColumn from '../../components/kanban/kanbanColumn';
import ICard from '../../types/ICard';
import pesquisa from './assets/search.png';
import Sidebar from '../../components/SideBar';
import logo from './assets/logo.png';
import { set } from 'mobx';
import ModalArquivados from './Arquivados';
import DropdownMenu from '../../components/MenuCRM';
import Cadastro from '../Cadastro';


const CabecalhoEstilizado = styled.header`
    background-color: black;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
`
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: .1;
    width: 100%;
`
const LinkEstilizado = styled.a`
 color: var(--cinza-claro);
 font-weight: 700;
 margin: 0px;
 margin-left: 5px;
`
const LinkEstilizado2 = styled(LinkEstilizado)`
font-weight: 500;
text-decoration: none;
color: var(--cinza-claro);
margin: 10px;
cursor: pointer;
`
const InputCustomizado = styled.input`
  flex: 1;
  border: none;
  background: none;
  outline: none;
`
const SpanCustomizado = styled.span`
  background-image: url(${pesquisa});
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
background-position: 10px;
`
const Logo = styled.img`
  width: 23px;
`
const Kanban = styled.div`
  display: flex;
  gap: 14px;
  margin-top: 45px;
  padding: 24px;
  width: 94%;
  height: 90vh;
  overflow-x: auto;
`
const Titulo = styled.h2`
  color: var(--cinza-claro);
  font-weight: 700;
  text-align: center;
  padding-left: 300px;
  flex: 1; /* Permite que o título ocupe o espaço disponível */
  margin: 5px;
  @media (max-width: 768px) {
    display: none;
  }
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 15px 0px 5px;
`
const ContainerPesquisa = styled.div`
  display: flex;
  align-items: center;
  background-color:rgb(255, 255, 255);
  border-radius: 8px;
  padding: 5px 16px;
`

export default function Crm(){
  const {usuario} = autenticaStore;
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [activeColumns, setActiveColumns] = useState<IColumn[]>([]);
  const [columnsDisbled, setColumnDisbled] = useState<IColumn[] | undefined>();
  const [cards, setCards] = useState<ICard[]>([]);
  const [cardsDisbled, setCardsDisbled] = useState<ICard[] | undefined>();
  const [refreshCards, setRefreshCards] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState('leads');
  const [Arquivados, setArquivados] = useState(false);
  const showSideBar = () => setSideBar(!sideBar);    
  const handleLogout = () => {
    autenticaStore.logout();
  }
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${usuario.token}`,
    },
  } 
  useEffect(() => {
    http.get<IColumn[]>('column', axiosConfig)
      .then(resposta => {

        const activeColumns = resposta.data.filter((column: IColumn) => column.active);
        const disabledColumns = resposta.data.filter((column: IColumn) => !column.active);
        const columnsByType = resposta.data.filter((column: IColumn) => column.active && column.tipo === selectedPanel);

        columnsByType.sort((a: IColumn, b: IColumn) => a.position - b.position);
        activeColumns.sort((a: IColumn, b: IColumn) => a.position - b.position);
        setActiveColumns(activeColumns);
        setColumns(columnsByType);
        setColumnDisbled(disabledColumns);

        let cardsByColumn = "card/get-cards-by-column?"
        cardsByColumn += activeColumns.sort((a: IColumn, b: IColumn) => a.position - b.position).map((column: IColumn) => `statusId=${column.id}`).join('&');

        //console.log("CARDS BY COLUMN FILTRO")
        //console.log(cardsByColumn)
        http.get<ICard[]>(cardsByColumn, axiosConfig)
        .then(resposta => {
          setCards(resposta.data);
          //console.log("TODOS CARDS FILTRADOS")
          //console.log(resposta.data)
          
        })
        .catch(erro => {
          alert(erro);
        });

        //console.log("COLUNAS ativas")
        //console.log(activeColumns)
        //console.log("COLUNAS desativadas")
        //console.log(disabledColumns)
      })
      .catch(erro => {
        alert(erro);
      });
  }, [refreshCards, selectedPanel]);

  useEffect(() => {
    if (columns.length > 0 && cards.length > 0) {
      movimentoCards();
    }
  }, [columns, cards]);

  function movimentoCards () {
    const cards = document.querySelectorAll('.kanban-card');
    const columns = document.querySelectorAll('.kanban-cards');

    cards.forEach(card => {
        card.addEventListener('dragstart', (e) => {
            (e.currentTarget as HTMLElement).classList.add('dragging');
        });

        card.addEventListener('dragend', (e) => {
            (e.currentTarget as HTMLElement).classList.remove('dragging');
        });
    });

    columns.forEach(column => {
        column.addEventListener('dragover', (e) => {
            e.preventDefault();
            (e.currentTarget as HTMLElement).classList.add('cards-hover');
        });

        column.addEventListener('dragleave', (e) => {
            (e.currentTarget as HTMLElement).classList.remove('cards-hover');
        });

        column.addEventListener('drop', (e) => {
            (e.currentTarget as HTMLElement).classList.remove('cards-hover');
            const dragCard = document.querySelector('.kanban-card.dragging') as HTMLElement;
            if (dragCard) {
                (e.currentTarget as HTMLElement).appendChild(dragCard);
            }
        });
    });

    return () => {
        cards.forEach(card => {
            card.removeEventListener('dragstart', () => {});
            card.removeEventListener('dragend', () => {});
        });

        columns.forEach(column => {
            column.removeEventListener('dragover', () => {});
            column.removeEventListener('dragleave', () => {});
            column.removeEventListener('drop', () => {});
        });
    };
  }

  function handleRefreshCards() {
    setRefreshCards(!refreshCards);
  }  

  const ArquivadosOpen = () => {
    setArquivados(true);
  };

  const ArquivadosClose = () => {
    setArquivados(false);
  };

  const handleMenuSelect = (value: string) => {
    //console.log("Menu selecionado:", value);
    setSelectedPanel(value);
  };

  const capitalizeFirstLetter = (string: string): string => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <CabecalhoEstilizado>          
          <Container>
            <Header>
            <div onClick={showSideBar}>
                <LinkEstilizado href="#"><Logo src={logo} alt="logo RH" /></LinkEstilizado>
                {sideBar && <Sidebar active={sideBar}  setActive={setSideBar} />} 
            </div>
              <Titulo>Gestão de {capitalizeFirstLetter(selectedPanel)}</Titulo>
              {/* <LinkEstilizado2 onClick={() => ArquivadosOpen()} >Arquivados</LinkEstilizado2> */}
              <ContainerPesquisa>
                <InputCustomizado placeholder="Pesquisar..." />
                <SpanCustomizado />
              </ContainerPesquisa>
              <DropdownMenu onSelect={handleMenuSelect} />
            </Header>
          </Container>
          
      </CabecalhoEstilizado>
      <ModalArquivados open={Arquivados} handleClose={ArquivadosClose} />
      <Kanban>      
        {columns.map((prop, index) => (
          prop.position !== 0 && (
          <KanbanColumn 
            key={index} 
            name={prop.name} 
            id={prop.id} 
            refreshCards={handleRefreshCards} 
            colunas={activeColumns}
            cards={Array.isArray(cards) ? cards.filter((card: ICard) => card.statusId === prop.id) : []}
          /> 
        )             
        ))}        
      </Kanban>
    </>
  );
}
